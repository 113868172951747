<template>
  <div id="livraison">
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="customer.name"
          :rules="defaultRules"
          label="Nom"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="customer.first_name"
          :rules="defaultRules"
          label="Prénom"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.tel"
          :rules="defaultRules"
          label="Téléphone"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.adr1"
          :rules="defaultRules"
          label="Adresse"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.adr2"
          label="Complément d'adresse"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="customer.zip_code"
          :counter="10"
          :rules="defaultRules"
          label="Code postal"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="customer.city"
          :rules="defaultRules"
          label="Ville"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="customer.country_id"
          :items="countries"
          :rules="defaultRules"
          item-text="name"
          item-value="id"
          label="Pays"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox v-model="cgvCgu" :rules="checkboxRequiredRules">
          <span slot="label">
            J'accepte la politique de confidentialité et les <a href='https://www.lesreparables.fr/cgv-cgu/'
                                                                target='_blank' @click.stop>cgv / cgu</a>
          </span>
        </v-checkbox>
        <v-checkbox v-if="partnerEngagementSentence" v-model="partnerEngagment" :rules="checkboxRequiredRules">
          <span slot="label">{{ partnerEngagementSentence }}</span>
        </v-checkbox>
        <v-checkbox
          v-model="customer.newsletter_subscribed"
          label="J'accepte de recevoir des actualités des Réparables (pas de spam, nous vous envoyons des nouvelles une fois par mois 😉)"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapState} from "vuex";
import axios from "axios";
import config from "../../config";

export default {
  data: () => ({
    defaultRules: [
      v => !!v || 'Ce champs est requis'
    ],
    emailRules: [
      v => !!v || 'Ce champs est requis',
      v => /.+@.+\..+/.test(v) || 'Le format n\'est pas celui d\'un email',
    ],
    checkboxRequiredRules: [
      v => !!v || 'Vous devez accepter les conditions'
    ],
    partnerEngagment: false,
    countries: [{id: 1, name: 'France'}]
  }),
  props: {
    customer: {
      type: Object,
      required: true
    },
    cgvCguAccepted: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    'customer.country_id': function (id) {
      this.customer.country_code = this.countries.find((country) => country.id === id)?.code_iso;
    }
  },
  computed: {
    ...mapState('flux-app', ['flux']),
    ...mapState('environment-app', ['environment']),
    cgvCgu: {
      get() {
        return this.cgvCguAccepted
      },
      set() {
        this.$emit('cgvCguAcceptedToggle')
      }
    },
    partnerEngagementSentence() {
      if (this.flux.brand === 'jott') {
        return "Je m’engage à envoyer une doudoune JOTT"
      }

      return this.environment.partnerEngagementSentence
    }
  },
  created() {
    axios({
      url: `${config.apiHost}/countries`,
      responseType: 'json'
    }).then(async (response) => {
      this.countries = response.data.countries.sort((a, b) => a.name.localeCompare(b.name))
    })
  }
}
</script>

<style scoped>
a {
  font-weight: bold;
  text-decoration: underline;
}

.error--text a {
  color: #ff5252;
}

@media (min-width: 768px) {
  #livraison {
    padding-left: 16px;
  }
}
</style>
