<template>
  <div>
    <v-banner v-if="false" :style="`background-color: ${environment.theme.bannerBackgroundColor}`" class="text-center">
      <p class="h6 m-0"></p>
    </v-banner>
    <div id="funnel">
      <timeline :step="step"/>
      <v-form v-if="step < 4" ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" md="8">
            <coordonnees
              v-if="step === 1"
              :cgv-cgu-accepted="cgvCguAccepted"
              :customer="cards.customerInfo"
              @cgvCguAcceptedToggle="toggleCgvCguAccepted()"
            />
            <livraison v-if="step === 2" @shipmentMethodSelected="updateShipmentMethod"/>
            <payment v-if="step === 3"/>
          </v-col>
          <v-col cols="12" md="4">
            <resume
              :cards="cards"
              :error="error"
              :is-next-step-disabled="nextStepDisabled"
              :is-next-step-loading="isLoading"
              :step="step"
              @prev-step="step--"
              @next-step="nextStep"
            />
          </v-col>
        </v-row>
      </v-form>
      <end v-else-if="step === 4"/>
    </div>
  </div>
</template>

<script>
import Livraison from "@/views/Livraison";
import Coordonnees from "@/views/Coordonnees";
import End from "@/views/end";
import Timeline from "@/components/shared/Timeline";
import Resume from "@/components/shared/Resume";
import {mapMutations, mapState} from "vuex";
import Payment from "@/views/Payment";
import axios from "axios";
import config from "../../../config";
import Bugsnag from "@bugsnag/js";
import {isInsuranceEligible} from "@/helper/ShipmentHelper";

const UNKNOWN_API_ERROR_MESSAGE = 'Une erreur est survenue. Veuillez ré-essayer.'

export default {
  name: "Funnel",
  components: {Payment, Resume, Timeline, Coordonnees, Livraison, End},
  data() {
    return {
      step: parseInt(this.$route.params.step),
      isLoading: false,
      error: null,
      nextStepDisabled: false
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products', 'cgvCguAccepted']),
    ...mapState('environment-app', ['environment']),
    ...mapState('flux-app', ['flux']),
  },
  methods: {
    ...mapMutations('cart-app', ['setOrderId', 'toggleCgvCguAccepted', 'setInsurance']),
    async nextStep() {
      this.error = null

      try {
        if (!this.$refs.form.validate()) {
          return
        }

        if (this.step === 2) {
          this.checkShipment()

          if (!this.cards.customerInfo.id || !this.cards.orderId) {
            this.isLoading = true
            await this.storeOrderAndCustomer()
          }
        }

        this.step++
      } catch (e) {
        this.error = this.error || UNKNOWN_API_ERROR_MESSAGE
      } finally {
        this.isLoading = false
      }
    },
    async storeOrderAndCustomer() {
      return Promise.resolve(axios({
          url: `${config.apiHost}/calc-customer/add`,
          method: 'POST',
          responseType: 'json',
          data: {
            ...this.cards.customerInfo,
            origin: this.environment.origin ?? this.flux.brand?.toUpperCase() ?? null
          }
        }).then(async (response) => {
          if (response.data.status !== 'success' || !response.data.member.id) {
            throw new Error()
          }

          this.cards.customerInfo.id = response.data.member.id
          await this.storeOrder();
        })
      )
    },
    async storeOrder() {
      return Promise.resolve(axios({
          url: `${config.apiHost}/calc-orders/add`,
          method: 'POST',
          responseType: 'json',
          data: {
            orderInfos: this.cards,
            products: this.products,
            cgv_cgu_accepted: this.cgvCguAccepted,
            origin: this.environment.origin ?? this.flux.brand?.toUpperCase() ?? null
          }
        }).then((response) => {
          if (response.data.status !== 'success' || !response.data.order.id) {
            throw new Error()
          }

          this.setOrderId(response.data.order.id)
        })
      )
    },
    async updateShipmentMethod() {
      this.error = ''

      if (!this.cards.orderId) {
        return
      }

      this.error = ''
      this.isLoading = true

      const url = `${config.apiHost}/orders/${this.cards.orderId}/shipment`
      try {
        await axios({
          url,
          method: 'PATCH',
          responseType: 'json',
          data: this.cards.shippingInfo,
        })
      } catch (e) {
        Bugsnag.notify(JSON.stringify({
          url,
          status: e.response.status,
          response: e.response.data,
          data: this.cards.shippingInfo
        }))
        this.error = UNKNOWN_API_ERROR_MESSAGE
      }

      this.isLoading = false

      if (!isInsuranceEligible(this.cards.shippingInfo)) {
        this.setInsurance(null)
      }
    },
    checkShipment() {
      if (!this.cards.shippingInfo) {
        this.error = 'Vous devez sélectionner une méthode de livraison'
      }

      if (this.cards.shippingInfo.type === 'mr' && !this.cards.shippingInfo.relay_id) {
        this.error = 'Vous devez sélectionner un point relais'
      }

      if (this.cards.shippingInfo.cle === 'pr_entreprise' && !this.cards.shippingInfo.company_name) {
        this.error = 'Vous devez entrer le nom de votre entreprise'
      }

      if (this.error) {
        throw Error
      }
    }
  },
  watch: {
    step() {
      this.$router.push({path: `/step/${this.step}`})
    },
    error(newValue) {
      this.nextStepDisabled = !!newValue && newValue !== UNKNOWN_API_ERROR_MESSAGE
    }
  },
  created() {
    if (this.products.length === 0 && this.step !== 4) {
      this.$router.push({path: '/'})
    }
  }
}
</script>

<style>
#funnel {
  padding: 1rem;
  overflow-x: hidden;
}

.v-application--wrap {
  min-height: inherit;
}
</style>
