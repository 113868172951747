<template>
  <div class="livraison" style="padding: 1rem;">
    <insurance v-if="showInsurance" @selected="recalcAndRepostPaymentIntent"/>
    <div v-if="!modeFree">
      <div><h4>PAIEMENT</h4></div>
      <!-- Display a payment form -->
      <form id="payment-form">
        <v-text-field
          v-if="loaded"
          v-model="code_promo"
          placeholder="Code promo"
          @blur="checkPromo"
          @focus="blockBtn"
          @keydown.enter.prevent.stop
        >
          <template v-slot:append-outer>
            <v-btn class="btn btn-1" small>Appliquer</v-btn>
          </template>
        </v-text-field>
        <div id="payment-element" class="my-4">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <div class="text-center">
          <div id="submit">
            <div id="spinner" class="spinner hidden"></div>
            <div v-if="loaded === false">
              <p>Veuillez patienter, notre module de paiement va se charger dans quelques secondes</p>
              <v-btn id="button-text" class="btn btn-1" disabled>VALIDER ET PROCEDER AU PAIEMENT</v-btn>
            </div>
            <div v-else>
              <div v-if="cards.infoCode">
                <div v-if="cards.infoCode.erreur">
                  <v-alert type="error">
                    {{ cards.infoCode.erreur }}
                  </v-alert>
                </div>
                <div v-else>
                  <v-alert v-if="cards.infoCode.name" type="success">
                    {{ cards.infoCode.name }}
                  </v-alert>
                </div>
              </div>
              <br/>
              <v-btn id="button-text" :disabled="btnDisabled" class="btn btn-1" @click="gopi">
                <spinner v-if="isLoading"/>
                <span v-else>VALIDER ET PROCEDER AU PAIEMENT</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div id="payment-message" class="hidden"></div>
      </form>
    </div>
    <div v-if="modeFree">
      <div><h4>VOUS N'AVEZ RIEN A PAYER</h4></div>
      <v-alert v-if="cards.infoCode.name" class="my-4" type="success">
        {{ cards.infoCode.name }}
      </v-alert>
      <p>Suite à l'utilisation du code Promo : <b>{{ cards.infoCode.coupon }}</b>, vous n'avez rien à payer.<br/>
        Cliquez simplement sur le bouton ci-dessous pour valider votre commande.</p>
      <div class="text-center">
        <div id="button-text" class="btn btn-1" @click="finalize">
          <spinner v-if="isLoading"/>
          <span v-else>FINALISER MA COMMANDE</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex'
import axios from '@/axios.js'
import config from '../../config'
import Spinner from "@/components/shared/Spinner";
import Insurance from "@/components/shared/Insurance.vue";
import {isInsuranceEligible} from "@/helper/ShipmentHelper";

export default {
  components: {Insurance, Spinner},
  data() {
    return {
      stripe: '',
      elements: '',
      loaded: false,
      code_promo: null,
      modeFree: false,
      resteCp: 0,
      btnDisabled: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState('cart-app', ['cards']),
    ...mapState('environment-app', ['environment']),
    ...mapState('flux-app', ['flux']),
    showInsurance() {
      return this.environment.shipmentProtection && this.isInsuranceEligible(this.cards.shippingInfo)
    }
  },
  created() {
    this.setTotalRemise(null)
    this.setInfoCode({erreur: null})
  },
  methods: {
    isInsuranceEligible,
    ...mapMutations('cart-app', ['setTotalRemise', 'setInfoCode']),
    includeStripe(URL, callback) {
      let documentTag = document, tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) {
        object.addEventListener('load', function (e) {
          callback(null, e);
        }, false);
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    blockBtn() {
      this.btnDisabled = true
    },
    recalcAndRepostPaymentIntent() {
      this.modeFree = false

      let price_to_discount = this.cards.totalWithPort
      if (this.cards.infoCode.repairs_only) {
        price_to_discount = this.cards.totalTTC
      }

      let discount = 0
      if (this.cards.infoCode.type == 'percentage') {
        discount = price_to_discount * this.cards.infoCode.montant / 100
      }
      if (this.cards.infoCode.type == 'flat') {
        discount = this.cards.infoCode.montant
      }

      if (discount > price_to_discount) {
        discount = price_to_discount
      }

      let final_price = price_to_discount - discount
      if (this.cards.infoCode.repairs_only) {
        const port = this.cards.totalWithPort - this.cards.totalTTC
        final_price += port
      }

      final_price = Math.round(final_price * 100) / 100

      if (final_price <= 0) {
        if (this.cards.infoCode.type === 'flat') {
          this.resteCp = this.cards.infoCode.montant - discount
        }

        final_price = 0
      }

      if (final_price === this.cards.totalWithPort) {
        final_price = null
      }

      this.setTotalRemise(final_price)

      if (this.cards.totalWithPortR > 0 || this.cards.infoCode.erreur !== undefined) {
        this.modeFree = false
        let self = this
        this.initialize(self)
      } else {
        this.modeFree = true
      }
    },
    async finalize() {
      this.isLoading = true
      //On update la commande en y associant le coupon_id, coupon_name et montant remise.
      // On passe le count d'utilisation à +1
      await this.associateCp()
      // On valide la commande
      this.validateFree()
    },
    async associateCp() {
      await axios({
        url: `${config.apiHost}/associate-cp`,
        method: 'POST',
        responseType: 'json',
        data: {
          order_id: this.cards.orderId,
          coupon_id: this.cards.infoCode.id,
          coupon_code: this.cards.infoCode.coupon,
          montant_remise: this.cards.totalWithPortR,
          reste: this.resteCp
        }
      })
    },
    validateFree() {
      return new Promise(() => {
        axios({
          url: `${config.apiHost}/validate-commande`,
          method: 'POST',
          responseType: 'json',
          data: {
            order_id: this.cards.orderId,
          }
        }).then((response) => {
          if (response.data.status === 'success') {
            window.location.href = this.environment.redirectUrlAfterPayment ?? `${config.basePath}/#/step/4?orderid=${this.cards.orderId}`
          }
        })
      })
    },
    checkPromo() {
      this.btnDisabled = false

      if (!this.code_promo && Object.keys(this.cards.infoCode).length === 0) {
        return
      }

      this.setInfoCode({erreur: null})
      this.setTotalRemise(null)

      if (!this.code_promo) {
        this.recalcAndRepostPaymentIntent()
        return
      }

      return new Promise(() => {
        axios({
          url: `${config.apiHost}/valid-cp`,
          method: 'POST',
          responseType: 'json',
          data: {
            coupon: this.code_promo,
            order: this.cards.orderId
          }
        }).then((response) => {
          if (response.data.status === 'success' && response.data.coupon) {
            if (response.data.coupon.erreur === undefined) {
              this.setInfoCode(response.data.coupon)
            } else {
              this.setTotalRemise(null)
              this.setInfoCode({
                "erreur": response.data.coupon.erreur
              })
            }
            this.recalcAndRepostPaymentIntent()
          }
        }).catch(() => {
          this.setInfoCode({
            "erreur": 'Une erreur est survenue, veuillez réessayer'
          })
        })
      })
    },
    gopi() {
      this.isLoading = true
      var elements = this.elements
      this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.environment.redirectUrlAfterPayment ?? `${config.basePath}/#/step/4?orderid=${this.cards.orderId}`
        },
      })
    },
// Fetches a payment intent and captures the client secret
    initialize: async (obj) => {
      obj.loaded = false
      document.getElementById('payment-element').innerText = ''
      let montant
      let formData = new FormData();
      formData.append('orderId', obj.cards.orderId)
      formData.append('memberId', obj.cards.customerInfo.id)
      formData.append('email', obj.cards.customerInfo.email)
      formData.append('couponId', obj.cards.infoCode.id)
      formData.append('couponCode', obj.cards.infoCode.coupon)
      formData.append('montantRemise', obj.cards.totalWithPortR)
      formData.append('reste', obj.cards.resteCp ?? '')

      if (obj.cards.totalWithPortR === null) {
        montant = Math.round(obj.cards.totalWithPort * 100)
      } else {
        montant = Math.round(obj.cards.totalWithPortR * 100)
      }
      formData.append('amount', montant)
      axios.post(`${config.apiHost}/payment-intent`, formData, {}).then((response) => {
        let clientSecret = response.data.clientSecret
        obj.elements = obj.stripe.elements({clientSecret})
        const paymentElement = obj.elements.create("payment")
        paymentElement.on("ready", function () {
          obj.loaded = true
        })
        paymentElement.mount("#payment-element");
      })
        .catch(function () {
          console.log('FAILURE!!');
        });
    },
  },
  mounted() {
    if (!this.cards.customerInfo.id) {
      return
    }

    axios({
      url: `${config.apiHost}/orders/${this.cards.orderId}/payment-details`,
      responseType: 'json'
    }).then((response) => {
      this.includeStripe('js.stripe.com/v3/', function () {
        var self = this;
        self.stripe = window.Stripe(response.data.stripe_public_key)
        this.initialize(self)
      }.bind(this));
    })
  },
}
</script>
<style scoped>
.my-input {
  padding: 10px;
  border: 1px solid #ccc;
}

.StripeElement--focus {
  padding: 10px;
  border: 2px solid #000;
}
</style>
